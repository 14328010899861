
import React from 'react';
import Typed from 'react-typed';

const Header = () => {
    return (
        <div className="header-wraper">
            <div className="main-info">
                <h1>Bienvenue dans mon portfolio</h1>
                <Typed 
                className="typed-text"
                strings={['PHP/Laravel', 'C#/ASP.Net', 'Java/EE/Spring/Hibernate', 'Services Web Restful et SOAP', 'SpringBoot', 'HTML/CSS/JavaScript', 'Bootstrap', 'Angular/React/VueJs', 'JQuery', 'AJAX']}
                typeSpeed={40}
                backSpeed={60}
                loop
                />

            </div>   
        </div>
    )
}

export default Header
