import React from 'react'
import logo from '../image.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

const Navbar = () => {
    return (

<nav className="navbar navbar-expand-lg navbar-light bg-dark">
  <div className="container">
  <div className="navbar-brand" ><img src={logo} alt='logo...' className='logo '/></div>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <FontAwesomeIcon icon={faBars} style={{color:'white'}} />
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0" id="nav-nav">
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="#propos">A propos de moi</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#projets">Projets Réalisés</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#formation">Formation & Compétences Techniques</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
    )
}

export default Navbar
