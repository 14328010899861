import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/Navbar';
import Header from './components/Header';
import Section from './components/Section';
function App() {

  return (
    <>
   <Navbar />
   <Header />
   <Section />
   </>
  );
}

export default App;
