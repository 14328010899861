import React from 'react';
import myVid from '../MyMovie.mp4';
import myVid1 from '../BrookCoCode.mp4';
import myVid3 from '../Reddit.mp4';
import myVid2 from '../quizz.mp4';
import geraldGodin from '../icons/gerald_godin_logo.png';
import agile_scrum from '../icons/agile_scrum.png';
import angular from '../icons/angular.png';
import html_5 from '../icons/html_5.png';
import android_os from '../icons/android_os.png';
import android_studio from '../icons/android_studio.png';
import asp_net from '../icons/asp_net.png';
import bootstrap from '../icons/bootsrap.png';
import c_sharp from '../icons/c_sharp.png';
import css3 from '../icons/css3.png';
import eclipse from '../icons/eclipse.png';
import gitlab from '../icons/gitlab.png';
import java from '../icons/java.png';
import javaee from '../icons/javaee.png';
import javascript from '../icons/javascript.png';
import jquery from '../icons/jquery.png';
import laravel from '../icons/laravel.png';
import mysql from '../icons/mysql.png';
import netbeans from '../icons/netbeans.png';
import php from '../icons/php.png';
import sqlServer from '../icons/sqlServer.png';
import visual_studio_code from '../icons/visual_studio_code.png';
import visual_studio from '../icons/visual_studio.png';



const Section = () => {
    return (
<div>
  <div className="bg-light" id="propos"> 
    <div className='aboutMe'>
      <div className="textAll">
        <h1 className="mb-0 text-uppercase">RACHID <span className="text-first">CHAKOR</span></h1>
        <div className="mb-5">
            <h5>Montreal Qc • &nbsp;
            <a  href="mailto:chakor.rachid.1@gmail.com">chakor.rachid.1@gmail.com</a>
            </h5>
        </div>
        <div className="mb-5">Programmeur web enthousiaste prêt à contribuer au succès de l’équipe grâce à un travail acharné, une attention portée aux détails et une excellente organisation. Une compréhension claire de Java EE, ASP.NET, AJAX, PHP, MySQL, HTML, CSS, JavaScript, JQuery. Motivé à grandir et à exceller dans toutes technologies.</div>
        <div className="mb-5">•	Toujours prêt à apprendre<br/>•	Entièrement bilingue (Français, Anglais)<br/>•	Connaissance approfondie des systèmes d’exploitation tel que : Windows, Linux, Mac OS	<br/>•	Facilité à trouver l’information dont j’ai besoin
        <br/>•	État d’esprit logique
        <br/>•	Esprit d’équipe</div>
        <div className="container-fluid">
            <a className="icons" href="https://www.linkedin.com/in/rachid-chakor-5a218124/">
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"></path>
            </svg>
            </a>
        </div>
      </div>
    </div>
  </div>

  <h2 className="py-5 text-uppercase text-white bg-dark shadow-lg rounded" id="projets">&nbsp; Pro<span className="text-first">jets</span> Réal<span className="text-first">isés</span></h2>
  <div className='myVidsContainer'>
    <div className="myvid1">
      <div className="card p-5 shadow-lg">
          <h2> Projet Session Hiver2021 ‘Brook&Co’<p></p></h2>
          <div className="d-flex flex-row">
            <div className="card">
              <div className="card-body">
                <h6 className="card-subtitle mb-2 text-muted ">Html, CSS, JavaScript, JSON, Bootstrap</h6>
                <p className="card-text">Brooke et Co est un club de loisirs qui se spécialise dans la vente des livres, des films et des jeux vidéo uniquement à ses membres pour effectuer des achats en ligne. L’application Web Brook&Co est responsive pour permettre aux mobinautes d’y naviguer facilement sans avoir à zoomer, scroller ou pincher dans les pages pour faire des achats.  </p>
              </div>
            </div>
          </div>
          <br/>
          <div className="flex-container">
            <video className='vidsProject' width="50%" height="" controls="true" autoplay="true" muted="" loop="true" class="vid0">
              <source src={myVid} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <video className='vidsProject' width="49%" height="" controls="true" autoplay="true" muted="" loop="true" class="vid">
              <source src={myVid1} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
        </div>
      </div>
    </div>

    <div className="myvid2">
      <div className="card p-5 shadow-lg">
        <h2> Projet Session Eté2021 ‘Reddit’ <p></p></h2>
        <div className="d-flex flex-row">
          <div className="card">
            <div className="card-body">
              <h6 className="card-subtitle mb-2 text-muted ">Laravel, MySQL, Bootstrap, JQuery</h6>
              <p className="card-text">Reddit est un site communautaire permettant à ses utilisateurs de s’échanger des liens avec 
                une description mais également de commenter et voter pour les liens les plus intéressantes

              </p>
            </div>
          </div>
        </div>
        <br/>
        <div className="d-flex flex-row">
          <video width="60%" height="" controls="true" autoplay="true" muted="" loop="true" className="vid1">
            <source src={myVid3} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>

    <div className="myvid3">
      <div className="card p-5 shadow-lg">
        <h2> Projet Session Hiver2021 ‘Gestion Quiz’<p></p></h2>
        <div className="d-flex flex-row">
          <div className="card">
            <div className="card-body">
              <h6 className="card-subtitle mb-2 text-muted ">ASP.Net Core MVC, Razor, CSS, Bootstrap, SQL Server, Déploiement en AWS</h6>
              <p className="card-text">L’application Gestion Quiz permet à l’utilisateur, après l’authentification, de générer un nouveau quiz en lui demandant de saisir combien de questions de niveau facile, combien de questions de niveau moyen et combien de questions de niveau difficile. Et puis, les quiz générés seront affichés sur la page ‘Passer un quiz’. Lorsque l’utilisateur passe un quiz, il peut le trouver sur la page ‘Réviser quiz’, là où il peut visualiser ses bonnes et mauvaises réponses, ainsi que sa note finale. </p>
            </div>
          </div>
        </div>
        <br/>
        <div className="d-flex flex-row">
          <video width="60%" height="" controls="true" autoplay="true" muted="" loop="true" className="vid1">
            <source src={myVid2} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  </div>
    <h2 className="text-uppercase text-white bg-dark shadow-lg rounded" id="formation">&nbsp; <span className="text-first">Form</span>ation &amp; <span className="text-first">COMP</span>ÉTENCES <span className="text-first">TECH</span>NIQUES</h2>
    <section className="section-3 bg-section" id="formation-compétences-techniques">
      <div className="d-flex flex-column flex-md-row shadow-lg rounded" id="gerald">
        <div className="flex-grow-1">
          <h3 className="mb-0"><img className="dev-icons-gerald" src={geraldGodin} alt="" /> Cégep Gérald-Godin</h3>
          <i className="display-6 AEC">AEC : Programmation en technologies Web</i>
          <p>15615 GOUIN BOUL O, SAINTE-GENEVIEVE QC H9H 5K8</p>
        </div>
        <div className="flex-shrink-0">
          <p className="text-first-date h4">DE OCTOBER 2020 À JANVIER 2022</p>
        </div>
      </div>
      
      <br/>

      <div className='frontBack_container'>
        <div className="card shadow-lg" id="frontdiv">
          <div className="container" id="front">
            <div className="row">
              <div className="col mb-2 text-muted text-center">Programmation côté client ( Front-End )</div>
            </div>
            <div className="frontElement_container">
              <div><img className="dev-icons" src={angular} alt="" srcset=""/>Angular</div>
              <div><img className="dev-icons" src={html_5} alt="" srcset=""/>HTML5</div>
              <div><img className="dev-icons" src={css3} alt="" srcset=""/>CSS</div>
              <div><img className="dev-icons" src={bootstrap} alt="" srcset=""/>Bootstrap</div>
              <div><img className="dev-icons" src={javascript} alt="" srcset=""/>JavaScript</div>
              <div><img className="dev-icons" src={jquery} alt="" srcset=""/> jQuery</div>
            </div>
          </div>
        </div>
        
        <div className="card shadow-lg" id="backdiv">
          <div className="row">
            <div className="col mb-2 text-muted text-center">Programmation côté serveur ( Back-End )</div>
          </div>
          <div className="backElement_container">
            <div><img className="dev-icons" src={java} alt="" srcset=""/> Java</div>
            <div><img className="dev-icons" src={javaee} alt="" srcset=""/>J2EE</div>
            <div><img className="dev-icons" src={php} alt="" srcset=""/> PHP</div>
            <div><img className="dev-icons" src={laravel} alt="" srcset=""/>Laravel</div>
            <div><img className="dev-icons" src={c_sharp} alt="" srcset=""/> C#</div>
            <div><img className="dev-icons" src={asp_net} alt="" srcset=""/> APS.NET</div>
          </div>
        </div>
      </div>

      <div className='baseMobileAnalyseAndOutil_container'>
        <div className='baseMobileAnalyse_container'>  
          <div className='dataBaseElements'>
            <div className="card shadow-lg">
              <div className="text-muted text-center">Base de données</div>
                <div className='mb-4'  id="base">
                  <div><img className="dev-icons" src={mysql} alt="" srcset=""/> <p>MySQL</p></div>
                  <div><img className="dev-icons" src={sqlServer} alt="" srcset=""/> <p>SQL Server</p></div>
                </div>
            </div>
          </div>

          <div className='mobileElement'>
            <div className="card shadow-lg">
              <div className="text-muted text-center">Mobile</div>
                <div className="mb-4 text-center" id="mobile">
                  <div><img className="dev-icons" src={android_os} alt="" srcset=""/> <p>Android</p></div>
                </div>
            </div>
          </div>

          <div className='AnalyseElement'>
            <div className="card shadow-lg">
              <div className="text-muted text-center">Analyse/gestion de projet</div>
                <div className="mb-4 text-center" id="scrum">
                  <div><img  className="dev-icons" src={agile_scrum} alt="" srcset=""/> <p>Agile / Scrum</p></div>
                </div>
            </div>
          </div>
        </div>


        <div className="card shadow-lg outilElements">
          <div className="text-muted text-center">Outils de développement</div>
            <div id="outil">
              <div><img className="dev-icons" src={gitlab} alt="" srcset=""/> <p className="mx-2">GitLab</p></div>
              <div><img className="dev-icons" src={eclipse} alt="" srcset=""/> <p className="mx-2">Eclipse</p></div>
              <div><img className="dev-icons" src={netbeans} alt="" srcset=""/> <p className="mx-2">Netbeans</p></div>
              <div><img className="dev-icons" src={visual_studio} alt="" srcset=""/> <p className="mx-2">Visual Studio</p></div>
              <div><img className="dev-icons" src={visual_studio_code} alt="" srcset=""/> <p className="mx-2">Visual Studio Code</p></div>
              <div><img className="dev-icons" src={android_studio} alt="" srcset=""/> <p className="mx-2">Android Studio</p></div>
            </div>
        </div>
      </div>
      
    </section>
</div>

    )
}

export default Section
